.printButton {
  background-color: #E2001B; 
  color: #ffffff;
  border: none;
  padding: 12pt 28pt;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 20px;
  transition-duration: 0.1s;
  border-radius: 1px;
  }
  .printButton:hover {
    background-color: #E83348; 
  }
  .printButton:focus { 
    outline: none;
    box-shadow: none;
 }

 .divButton{
   padding-top:  2pt;
   padding-right: 15pt;
 }